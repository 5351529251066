.panel-ficha>[class~="p-panel-header"] {
    background-color: #2f7bb1;
    color: #ffffff;
    text-align: center;
}

.tab-ficha>[class~="p-tabview"] {
    background-color: #DB8918 !important;
    color: #ffffff;
}

.pac-container {
    z-index: 9999 !important;
}

/*
.modal-ssize {
    min-width: 850px !important;
    max-width: 1050px !important;
}
*/

.p-dialog-header {
    background-color: #DB8918 !important;
    color: #ffffff !important;
}

.button-ficha {
    background-color: #DB8918 !important;
    color: #ffffff !important;
    border-color: #DB8918;
}

.button-ficha1 {
    background-color: #101D61 !important;
    color: #ffffff !important;
    border-color: #101D61;
}

.card-ficha>[class~="p-card-header"] {
    background-color: #2f7bb1 !important;
    color: #ffffff !important;   
    font-size: 18px; 
}

.align-right input {
    text-align: right;
}